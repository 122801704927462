@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import "../css/bootstrap.min.css";
@import "../css/font-awesome.min.css";
@import "../css/owl.carousel.css";
@import "../css/owl.transitions.css";
@import "../css/magnific-popup.css";
@import "../css/apps.css";
@import "../css/plyr.css";
@import "style";
@import "responsive";

.navbar-default {
	position: absolute !important;
	background: rgba(255,255,255,0.7) !important;
	border: none !important;
	border-radius: 0px !important; }
.banner-item {
	background-position: top; }

.banner-mask, .banner-background {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%; }

.banner-background {
	background-position: top;
	filter: blur(1.5px); }

.banner-mask {
	background: linear-gradient(to right, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.4) 50%,rgba(255,255,255,0) 100%); }

.banner-content {
	filter: none; }
.banner-title, .banner-subtitle {
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
 }	// color: white

* {
	font-family: Montserrat; }

body {
	margin: 0;
	padding: 0; }
