/*----------------------------
Button 
----------------------------*/

.btn {
    line-height: 38px;
    border: 2px solid $primaryColor;
    background-color: $primaryColor;
    color: #ffffff;
    padding: 0 29px;
    border-radius: 21px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease;
    font-size: 1.4rem;
    &:active,
    &:focus,
    &:active:focus,
    &:visited {
        background-color: $primaryColor;
        box-shadow: none;
        outline: none;
        color: #ffffff;
    }
    &:hover,
    &:hover:active,
    &:hover:focus,
    &:hover:active:focus {
        background-color: #111111;
        border-color: #111111;
        color: #ffffff;
        .fa {
            color: #ffffff;
        }
    }
    .fa {
        margin-right: 4px;
        color: $primaryColor;
    }
    &.btn-border {
        background-color: transparent;
        color: $primaryColor;
        &:active,
        &:focus,
        &:active:focus,
        &:visited {
            background-color: transparent;
            color: $primaryColor;
        }
        &:hover,
        &:hover:active,
        &:hover:focus,
        &:hover:active:focus {
            background-color: $primaryColor;
            color: #ffffff;
        }
        &.btn-white {
            background-color: transparent;
            color: #ffffff;
            &:focus,
            &:visited,
            &:active,
            &:focus:visited,
            &:active:focus {
                background-color: #ffffff;
                color: #ffffff;
            }
            &:hover,
            &:focus:hover,
            &:active:hover,
            &:active:focus:hover {
                color: $primaryColor;
            }
        }
        &.btn-black {
            background-color: transparent;
            color: #111111;
            border-color: #111111;
            &:focus,
            &:visited,
            &:active,
            &:focus:visited,
            &:active:focus {
                color: #111111;
                border-color: #111111;
            }
            &:hover,
            &:focus:hover,
            &:active:hover,
            &:active:focus:hover {
                color: #ffffff;
                border-color: $primaryColor;
            }
        }
    }
    &.btn-white {
        background-color: #ffffff;
        border-color: #ffffff;
        color: $primaryColor;
        &:active,
        &:focus,
        &:active:focus,
        &:visited {
            background-color: #ffffff;
            border-color: #ffffff;
            color: $primaryColor;
        }
        &:hover,
        &:hover:active,
        &:hover:focus,
        &:hover:active:focus {
            background-color: #f7f7f7;
            border-color: #f7f7f7;
            color: #111111;
        }
        &.btn-simple {
            color: #ffffff;
            &:focus,
            &:visited,
            &:active,
            &:active:focus {
                color: #ffffff;
                .fa {
                    color: #ffffff;
                }
            }
        }
    }
    &.btn-black {
        background-color: #111111;
        border-color: #111111;
        color: #ffffff;
        &:active,
        &:focus,
        &:active:focus,
        &:visited {
            background-color: #111111;
            border-color: #111111;
            color: #ffffff;
        }
        &:hover,
        &:hover:active,
        &:hover:focus,
        &:hover:active:focus {
            background-color: $primaryColor;
            border-color: $primaryColor;
            color: #ffffff;
        }
    }
    &.btn-simple {
        border-color: transparent;
        background-color: transparent;
        color: #111111;
        &:focus,
        &:visited,
        &:active,
        &:active:focus {
            border-color: transparent;
            background-color: transparent;
            color: #111111;
        }
        &:hover,
        &:active:hover,
        &:focus:hover,
        &:hover:active:focus {
            color: #ffffff;
            background-color: $primaryColor;
            border-color: $primaryColor;
        }
    }
    &.btn-lg {
        line-height: 49px;
        border-radius: 26.5px;
        padding: 0 39px;
        &.btn-simple {
            padding: 0 27px;
        }
    }
    &.btn-sm {
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 29px;
        border-radius: 17px;
        padding: 0 19px;
    }
}

.button-group {
    .btn {
        +.btn:not(.btn-simple) {
            margin-left: 26px;
        }
    }
}
