/*----------------------------
Blog
----------------------------*/

.blog-grid {
    margin-bottom: -30px;
}

.post {
    margin-bottom: 30px;
    background-color: #f7f7f7;
}

.post-thumb {
    display: block;
}

.post-content {
    padding: 56px 40px;
}

.post-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #111111;
    margin: 0;
    a {
        color: inherit;
        &:hover {
            color: $primaryColor;
        }
    }
}

.post-date {
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 15px;
}

.post-excerpt {
    margin-bottom: 0;
    p {
        margin-bottom: 0;
    }
}


/*** Blog List ***/

.blog-list {
    .post {
        zoom: 1;
        position: relative;
        overflow: hidden;
    }
    .post-thumb {
        float: left;
        width: 370px;
        +.post-content {
            width: calc(100% - 370px);
        }
    }
    .post-content {
        width: 100%;
        float: left;
        padding: 45px 30px;
    }
}

.nav-links {
    width: 100%;
    text-align: center;
    margin-top: 60px;
}

.page-numbers,
a.page-numbers {
    font-weight: 400;
    color: $baseColor;
    height: 50px;
    width: 50px;
    line-height: 48px;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    display: inline-block;
    +.page-numbers {
        margin-left: 16px;
    }
    &:focus,
    &:visted,
    &:active {
        color: $baseColor !important;
    }
    &.current {
        border-color: $primaryColor;
    }
    &:hover {
        color: $primaryColor;
    }
}


/*-------------------------------------------
Blog Single 
-------------------------------------------*/

.blog-single {
    .post {
        float: none;
        padding: 0;
        background-color: transparent;
        margin-bottom: 0;
    }
    .post-content {
        padding: 0;
    }
    .post-metas {
        margin-top: 20px;
        .post-meta {
            font-weight: 600;
            color: #111111;
            display: inline-block;
            +.post-meta {
                margin-left: 35px;
            }
        }
        a.post-meta {
            color: #111111;
            &:hover {
                color: $primaryColor;
            }
        }
    }
    .share-post {
        margin-top: 20px;
        font-size: 1.4rem;
        font-weight: 600;
        color: $primaryColor;
        label {
            font-weight: 600;
            margin: 0;
            display: inline-block;
        }
        a {
            display: inline-block;
            margin-left: 10px;
        }
    }
}

.post-entry {
    p {
        font-weight: 400;
        margin-bottom: 27px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    blockquote {
        font-weight: 400;
        font-size: 1.4rem;
        border-left: 5px solid $primaryColor;
        margin: 0 0 20px 30px;
        padding: 22px 17px;
    }
}

.author-box {
    zoom: 1;
    position: relative;
    overflow: hidden;
    padding: 60px;
    color: #ffffff;
    margin-top: 25px;
    background-color: $primaryColor;
    .author-thumb {
        float: left;
        width: 100px;
        margin-right: 30px;
        +.author-about {
            width: calc(100% - 130px);
        }
    }
    .author-about {
        width: 100%;
        float: left;
    }
    .author-name {
        font-weight: 700;
        font-size: 1.8rem;
        margin-top: 0;
    }
    .author-bio {
        font-weight: 400;
    }
    .author-post-link {
        font-weight: 700;
        font-size: 1.8rem;
        color: #ffffff;
        .fa {
            transition: all 0.3s ease;
        }
        &:hover {
            .fa {
                margin-left: 10px;
            }
        }
    }
}


/*** Comments give ***/

.comments-section {
    margin-top: 55px;
    .lead {
        font-size: 2.2rem;
        font-weight: 700;
    }
}

.comment-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.comment {
    +.comment {
        margin-top: 20px;
    }
}

.comment-wrap {
    zoom: 1;
    position: relative;
    overflow: hidden;
}

.commenter-thumb {
    width: 100px;
    float: left;
    margin-right: 30px;
    +.comment-body {
        width: calc(100% - 130px);
    }
}

.comment-body {
    width: 100%;
    float: left;
}

.comment-title {
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 22px;
}

.comment-reply {
    float: right;
    color: $primaryColor;
    &:hover {
        color: #111111;
    }
}

.comment-content {
    p {
        font-weight: 400;
    }
}

.child-comments {
    padding-left: 60px;
    .comment {
        margin-top: 20px;
    }
}


/*** Comment form ***/

.give-your-reply {
    margin-top: 55px;
    .commentform-header {
        margin-bottom: 35px;
        h4 {
            margin-top: 0;
            margin-bottom: 20px;
        }
        p {
            font-weight: 400;
        }
    }
    label {
        display: block;
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: 22px;
        input,
        textarea {
            margin-top: 2px;
        }
    }
    input:not([type=radio]):not([type=checkbox]),
    textarea {
        border: 1px solid #111111;
        background-color: transparent;
        font-weight: 400;
        padding: 8px 30px;
    }
    [type=submit].btn {
        text-transform: none;
    }
}


/*** Widgets ***/

.widget {
    +.widget {
        margin-top: 56px;
    }
    .searchform {
        position: relative;
         ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #111111;
            opacity: 0.44;
        }
         ::-moz-placeholder {
            /* Firefox 19+ */
            color: #111111;
            opacity: 0.44;
        }
         :-ms-input-placeholder {
            /* IE 10+ */
            color: #111111;
            opacity: 0.44;
        }
         :-moz-placeholder {
            /* Firefox 18- */
            color: #111111;
            opacity: 0.44;
        }
        [type=search] {
            background-color: transparent;
            border: 2px solid #e7e7e7;
            font-weight: 400;
            font-size: 1.4rem;
            padding: 0 30px;
            line-height: 46px;
        }
        [type=submit] {
            border: 2px solid transparent;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 46px;
            color: $primaryColor;
            width: 70px;
            text-align: center;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        >li {
            display: block;
            >a {
                display: inline-block;
                position: relative;
                color: #111111;
                font-weight: 400;
                &:hover {
                    color: $primaryColor;
                }
                &:before {
                    content: "\f105";
                    font-family: "FontAwesome";
                    font-size: inherit;
                    margin-right: 7px;
                }
            }
        }
    }
    .widget-post {
        zoom: 1;
        overflow: hidden;
        position: relative;
        +.widget-post {
            margin-top: 20px;
        }
    }
    .widget-post-thumb {
        width: 70px;
        margin-right: 20px;
        float: left;
        +.widget-post-body {
            width: calc(100% - 90px);
        }
    }
    .widget-post-body {
        float: left;
        width: 100%;
    }
    .widget-post-title {
        font-size: 1.8rem;
        font-weight: 600;
        margin-top: 6px;
        color: #111111;
        display: block;
        &:hover {
            color: $primaryColor;
        }
    }
    .widget-post-date {
        font-weight: 400;
    }
    .widget-tag {
        zoom: 1;
        position: relative;
        overflow: hidden;
        margin-right: -10px;
        margin-bottom: -10px;
        a {
            font-weight: 400;
            border: 1px solid #e7e7e7;
            display: inline-block;
            color: #111111;
            padding: 0 9px;
            line-height: 23px !important;
            margin: 0 6px 10px 0;
            &:hover {
                border-color: $primaryColor;
            }
        }
    }
    .social-media {
        a {
            display: inline-block;
            line-height: 1;
            font-size: 2.5rem;
            color: #111111;
            &:hover {
                color: $primaryColor;
            }
            +a {
                margin-left: 21px;
            }
        }
    }
    .instagram-photos {
        zoom: 1;
        position: relative;
        overflow: hidden;
        margin: 0 -10px -10px 0;
        .instagram-photo {
            float: left;
            width: 82px;
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
        }
    }
}

.widget-title {
    margin-top: 0;
    margin-bottom: 30px;
    padding-bottom: 10px;
    position: relative;
    &:after {
        content: "";
        bottom: 0;
        position: absolute;
        background-color: $primaryColor;
        height: 5px;
        width: 50px;
        left: 0;
    }
}
