/*--------------------------------
Contact Page
--------------------------------*/

.fluid-container {
    zoom: 1;
    position: relative;
    overflow: hidden;
}

.fluid-col-1 {
    width: 25%;
    float: left;
    display: inline-block;
}

.fluid-col-2 {
    width: 50%;
    float: left;
    display: inline-block;
}

.fluid-col-3 {
    width: 75%;
    float: left;
    display: inline-block;
}

.fluid-cdol-4 {
    width: 100%;
    float: left;
    display: inline-block;
}

.contact-info-item {
    background-color: $primaryColor;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding: 145px 50px;
    color: #ffffff;
    &:nth-child(2) {
        background-image: url(../images/contact/contact-item-2.jpg);
    }
    &:nth-child(4) {
        background-image: url(../images/contact/contact-item-4.jpg);
    }
    &:nth-child(2n) {
        &:before {
            content: "";
            position: absolute;
            background-color: rgba(#111111, 0.8);
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .contact-title,
    .contact-person,
    .contact-info {
        z-index: 1;
        position: relative;
    }
    .contact-title {
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1;
        padding-bottom: 22px;
        margin-top: 0;
        margin-bottom: 35px;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100px;
            height: 8px;
            background-color: #ffffff;
        }
    }
    .contact-person {
        font-size: 2.2rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    .contact-info {
        .fa {
            margin-right: 5px;
        }
    }
}

.contact-form-wrap {
    background-image: url(../images/contact/form.jpg);
    background-position: center center;
    background-size: cover;
    .overlay-black {
        background-color: rgba(#111111, 0.9);
        padding: 110px 244px;
    }
    .section-header {
        &:after {
            left: 0;
            margin-left: 0;
        }
    }
}

.contact-form {
    .btn.btn-white {
        text-transform: none;
        color: #111111;
        &:hover {
            color: #ffffff;
            background-color: $primaryColor;
            border-color: $primaryColor;
        }
    }
}

.form-map-section {
    .fluid-container {
        display: flex;
    }
    .fluid-col-2 {
        align-items: stretch;
    }
    .map-area {
        position: relative;
        .office-location {
            height: 100%;
        }
    }
}


/*** Basic Form rules **/

form {
    position: relative;
    p {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .text-white &,
    .theme-bg & {
         ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #ffffff;
        }
         ::-moz-placeholder {
            /* Firefox 19+ */
            color: #ffffff;
        }
         :-ms-input-placeholder {
            /* IE 10+ */
            color: #ffffff;
        }
         :-moz-placeholder {
            /* Firefox 18- */
            color: #ffffff;
        }
    }
}

input:not([type=radio]):not([type=checkbox]),
textarea {
    font-size: 1.2rem;
    color: #111111;
    width: 100%;
    background-color: #f7f7f7;
    border: none;
    padding: 9px 30px;
    .text-white &,
    .theme-bg & {
        color: #ffffff;
        background-color: rgba(#ffffff, 0.15);
    }
    &:focus {
        outline: none;
    }
}

button {
    &:focus {
        outline: none;
    }
}

textarea {
    resize: none;
}

.input-success,
.input-error {
    margin-bottom: 0;
    display: none;
    padding: 2px 15px;
    color: #ffffff;
}

.input-success {
    background-color: #16a085;
}

.input-error {
    background-color: #d0000b;
}
