//Fonts 
$baseFont: 'Montserrat',
sans-serif;
$baseFontSize: 1.4rem;
$baseFontWeight: 300;
$baseColor: #111111;
$baseLineHeight: 1.857143;
$headingFontWeight: 700;
$headignLineHeight: 1.176471;
//Color 
$primaryColor: #d0000b;
