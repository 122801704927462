/*----------------------------
Artist
----------------------------*/

.artist-section {
    padding-top: 180px;
}

.artists .col-md-4:nth-child(2) {
    margin-top: 80px;
}

.artist {
    width: 100%;
    max-width: 278px;
    margin: 0 auto;
    position: relative;
    +.artist {
        margin-top: 50px;
    }
    .artist-avatar {
        display: block;
        clear: both;
        position: relative;
        overflow: visible;
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 272px;
            height: 272px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../images/artist/hover-effect.png);
            top: 0;
            z-index: 0;
            transition: all 0.3s ease;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
        img {
            z-index: 1;
            position: relative;
        }
    }
    .artist-name {
        position: relative;
        text-transform: uppercase;
        color: $baseColor;
        padding-left: 65px;
        margin-top: 25px;
        margin-bottom: 0;
        a {
            color: inherit;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 9px;
            background-color: #111111;
            width: 50px;
            height: 5px;
        }
    }
    .artist-role {
        text-transform: uppercase;
        padding-left: 65px;
        margin-top: 5px;
        margin-bottom: 0;
    }
    &:hover {
        .artist-avatar {
            &:before {
                left: -50px;
            }
            &:after {
                right: -50px;
            }
        }
    }
}
