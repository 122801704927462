/*--------------------------------
Single Concert Page
--------------------------------*/

.concert-single {
    .concert-thumb {
        margin-bottom: 50px;
    }
    .concert-title {
        text-transform: uppercase;
        margin: 0;
    }
    .concert-metas {
        margin-top: 15px;
        margin-bottom: 0;
        .concert-meta {
            display: block;
            label {
                margin: 0;
            }
        }
    }
    .concert-entry {
        margin-top: 15px;
        p {
            margin-bottom: 54px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .schedules {
        margin-bottom: 54px;
        .schedule-item {
            +.schedule-item {
                margin-top: 50px;
            }
        }
        .schedule-title {
            text-transform: uppercase;
            font-size: 1.8rem;
            margin-top: 0;
            margin-bottom: 8px;
            &:before {
                content: "\f00c";
                font-family: "FontAwesome";
                margin-right: 8px;
            }
        }
    }
}

.performer-section {
    margin-top: 50px;
    .performer-title {
        margin-top: 0;
        margin-bottom: 50px;
        text-transform: uppercase;
    }
}


/*** Concert Single Widget ***/

.widget {
    .ticket-pricing {
        margin: -15px;
        zoom: 1;
        overflow: hidden;
        position: relative;
    }
    .ticket-pricing-item {
        margin: 15px;
        border: 1px solid $primaryColor;
        display: inline-block;
        float: left;
        width: calc(50% - 30px);
        text-align: center;
        padding: 25px 20px;
        .ticket-pack-name {
            font-weight: 500;
            line-height: 1.286;
            margin-bottom: 6px;
        }
        .ticket-price {
            font-size: 2.2rem;
            font-weight: 700;
            margin-bottom: 18px;
        }
    }
    .ticket-btn {
        font-size: 1.2rem;
        font-weight: 500;
        color: #ffffff;
        background-color: #111111;
        line-height: 29px;
        padding: 0 20px;
        border-radius: 14.5px;
        &:hover {
            background-color: $primaryColor;
            color: #ffffff;
        }
    }
    .upcoming-shows {
        zoom: 1;
        position: relative;
        overflow: hidden;
    }
    .upcoming-show-item {
        float: left;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        padding: 27px 0;
        &:first-child {
            padding-top: 0;
        }
        .show-content {
            display: inline-block;
        }
        .show-title {
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 5px;
        }
        .show-venue {
            margin-bottom: 0;
            font-weight: 500;
            line-height: 1.643;
        }
        .show-type {
            margin-bottom: 0;
            font-weight: 500;
            line-height: 1.643;
        }
        .ticket-btn {
            float: right;
            margin-top: 20px;
        }
    }
    .sponsors {
        zoom: 1;
        overflow: hidden;
        position: relative;
        margin: -33px 0;
    }
    .sponsor-item {
        display: inline-block;
        width: 33.333333%;
        position: relative;
        height: 100px;
        float: left;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
