@import '_variable.scss';
@media (max-width: 1620px) {
    .upcoming-album {
        .album-art {
            margin-right: 30px;
            width: 240px;
        }
        .album-content {
            width: calc(100% - 270px);
        }
    }
    .contact-form-wrap {
        .overlay-black {
            padding: 110px;
        }
    }
}

@media (max-width: 1199px) {
    .upcoming-album {
        padding: 60px;
        .album-art {
            margin-right: 0;
            width: 100%;
        }
        .album-content {
            width: 100%;
            margin-top: 20px;
        }
    }
    .artist {
        &:hover {
            .artist-avatar {
                &:before {
                    left: -40px;
                }
                &:after {
                    right: -40px;
                }
            }
        }
    }
    .subscription-area .subscription [type=email] {
        width: 290px;
    }
    .recent-albums {
        width: 370px;
    }
    .post-content {
        padding: 20px 30px;
    }
    .post-date {
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .section-padding {
        padding: 80px 0;
    }
    .footer-contact {
        margin-top: 10px;
    }
    .logo-social-area .socials {
        margin-top: 5px;
    }
    .page-header {
        padding: 100px 0;
    }
    .purchase-button-wrap .btn+.btn {
        margin-left: 12px;
    }
    .blog-list .post-content {
        padding: 25px 30px;
    }
    .widget .ticket-pricing-item {
        width: calc(100% - 30px);
    }
    .contact-info-item {
        padding: 60px 30px;
        .contact-title {
            font-size: 2.4rem;
        }
    }
}

@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,
    .navbar-right {
        float: none !important;
    }
    .navbar-default {
        .navbar-toggle {
            display: block;
            background-color: $primaryColor;
            border-color: $primaryColor;
            border-radius: 5px;
            margin: 33px 0;
            .icon-bar {
                background-color: #ffffff;
                transition: all 0.3s ease;
                opacity: 0;
                &:first-child {
                    transform: rotate(45deg);
                    opacity: 1;
                    margin-top: 6px;
                    margin-bottom: -6px;
                }
                &:last-child {
                    transform: rotate(315deg);
                    opacity: 1;
                    margin-top: -2px;
                    margin-bottom: 6px;
                }
            }
            &:focus,
            &:hover {
                background-color: $primaryColor;
            }
            &.collapsed {
                .icon-bar {
                    opacity: 1;
                    &:first-child,
                    &:last-child {
                        transform: rotate(0);
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                        margin-top: 4px;
                    }
                }
            }
        }
        &.affix {
            .navbar-toggle {
                margin: 13px 0;
            }
            .navbar-nav {
                >li {
                    >a {
                        line-height: 15px;
                    }
                }
            }
        }
        .navbar-nav {
            margin: 7.5px 0;
            max-height: 400px;
            overflow: auto;
            >li {
                >a {
                    line-height: 15px;
                    padding: 10px 15px;
                    display: inline-block;
                }
                &.dropdown {
                    a {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
            li {
                a {
                    display: inline-block;
                    &:after {
                        content: none;
                    }
                }
                &.dropdown {
                    &:before {
                        content: "\f067";
                        font-family: "FontAwesome";
                        position: absolute;
                        top: 0;
                        right: 0;
                        line-height: 15px;
                        padding: 10px 15px;
                    }
                    .dropdown {
                        >a {
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
            .dropdown-menu {
                display: none;
                opacity: 1;
                visibility: visible;
                position: static;
                width: 100%;
                box-shadow: none;
                float: none;
                >li {
                    >a {
                        font-weight: 400;
                        padding: 10px 15px;
                    }
                }
            }
        }
        .search-nav {
            .search-toggle {
                display: none;
            }
            .header-search-wrap {
                display: block;
                position: relative;
                width: 100%;
            }
        }
        .navbar-collapse {
            border-top: 1px solid transparent;
            box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.25);
            background-color: #ffffff;
            position: absolute;
            right: 15px;
            width: 360px;
            max-width: 100%;
        }
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in {
        display: block !important;
    }
    .banner-item {
        height: 600px;
    }
    .banner-title {
        font-size: 3.6rem;
    }
    .section-header {
        &.left-style {
            .section-title {
                font-size: 3rem;
            }
        }
    }
    .stage-title {
        font-size: 3rem;
    }
    .col-xs-12 {
        .artist {
            display: inline-block;
            width: 50%;
            text-align: center;
            max-width: none;
            float: left;
            .artist-avatar {
                max-width: 278px;
                float: none;
                margin: 0 auto;
            }
            .artist-name {
                max-width: 278px;
                float: none;
                margin: 25px auto 0;
                text-align: left;
            }
            .artist-role {
                margin: 5px auto 0;
                max-width: 278px;
                float: none;
                text-align: left;
            }
        }
    }
    .artists .col-md-4:nth-child(2) {
        margin-top: 0;
    }
    .show-date {
        width: 150px;
        .date {
            font-size: 2.4rem;
        }
        .day {
            font-size: 1.4rem;
        }
    }
    .show-hall .hall-name {
        font-size: 1.4rem;
    }
    .show-name {
        font-size: 1.4rem;
    }
    .grid-sizer {
        width: 20%;
    }
    .grid-item {
        width: 20%;
        img {
            width: 100%;
        }
        &.grid-item--width2 {
            width: 40%;
        }
        &.grid-item--width3 {
            width: 60%;
        }
    }
    .recent-albums {
        width: 345px;
    }
    .subscription-area .subscription [type=email] {
        width: 180px;
    }
    .band-info-items {
        margin-bottom: -40px;
    }
    .band-info-item {
        margin-bottom: 40px;
    }
    .band-artists {
        margin-bottom: -30px;
    }
    .band-artist {
        width: 33.333333%;
        max-width: 234px;
        margin-bottom: 30px;
        +.band-artist {
            margin-left: 9px;
        }
    }
    .band-artist:nth-child(odd) {
        margin-top: 0;
    }
    .amplitude-song-container {
        .play-now {
            opacity: 0;
        }
        &:hover {
            .play-now {
                opacity: 1;
            }
        }
    }
    .artist-info-section {
        .artist-image {
            position: relative;
            text-align: center;
            img {
                display: inline-block;
                margin: 0 auto;
            }
        }
    }
    .about-artist {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .amplitude-song-container {
        font-size: 1.4rem;
    }
    .song-meta-data {
        .song-artist {
            font-weight: 300;
            font-size: 1.2rem;
        }
    }
    .purchase-button-wrap {
        .btn {
            min-width: 128px;
            +.btn {
                margin-left: 5px;
            }
        }
    }
    .blog-list .post-thumb {
        width: 100%;
        img {
            width: 100%;
        }
        +.post-content {
            width: 100%;
        }
    }
    .page-numbers+.page-numbers,
    a.page-numbers+.page-numbers {
        margin-left: 5px;
    }
    .page-numbers,
    a.page-numbers {
        height: 32px;
        width: 32px;
        line-height: 30px;
    }
    .author-box {
        padding: 20px;
    }
    .performer-section {
        .col-xs-12 .artist {
            width: 100%;
        }
        .artists {
            .col-xs-12+.col-xs-12 {
                margin-top: 50px;
            }
        }
    }
    .fluid-container {
        .fluid-col-1 {
            width: 50%;
        }
        .fluid-col-2 {
            width: 100%;
        }
    }
    .contact-info-item {
        &:nth-child(3) {
            background-image: url(../images/contact/contact-item-4.jpg);
            &:before {
                content: "";
                position: absolute;
                background-color: rgba(17, 17, 17, 0.8);
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        &:nth-child(4) {
            background-color: $primaryColor;
            background-image: none;
            &:before {
                content: none;
            }
        }
    }
    .form-map-section {
        .fluid-container {
            display: block;
        }
        .fluid-col-2 {
            align-items: baseline;
        }
        .map-area .office-location {
            height: 360px;
        }
    }
    .faq-section {
        .col-md-6+.col-md-6 {
            margin-top: 60px;
        }
    }
}

@media (max-width: 767px) {
    .navbar-default,
    .navbar-default.affix {
        min-height: 60px;
        background-color: #ffffff;
        box-shadow: 0 3px 5px -1px rgba(#000000, 0.25);
        position: fixed;
        .navbar-toggle {
            margin: 13px 15px 13px 0;
        }
        .navbar-brand {
            height: 60px;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 17px;
            padding-bottom: 17px;
        }
    }
    .fluid-container .half {
        width: 100%;
    }
    .upcoming-album {
        .album-art {
            width: 240px;
            margin-right: 30px;
        }
        .album-content {
            width: calc(100% - 270px);
            margin-top: 0;
        }
    }
    .section-header {
        &.left-style {
            text-align: center;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 20px;
            margin-bottom: 40px;
            &:after {
                bottom: 0;
                top: auto;
                left: 50%;
                margin-left: -50px;
            }
        }
    }
    .stage-countdown-wrap {
        float: none;
        text-align: center;
        max-width: 420px;
        margin: 0 auto;
        width: 100%;
    }
    .stage-title {
        font-size: 2.4rem;
    }
    .table-responsive {
        border: none;
    }
    .recent-albums {
        width: 450px;
        float: none;
        margin: 0 auto;
    }
    .subscription-area .subscription {
        max-width: 440px;
        margin: 40px auto 0;
        [type=email] {
            width: 275px;
        }
    }
    .copyright-footer {
        .col-xs-12 {
            text-align: center;
            .credit-text {
                text-align: center;
            }
        }
    }
    .page-header {
        padding: 60px 0;
    }
    .band-img {
        margin: -60px auto 0;
    }
    .band-artist+.band-artist {
        margin-left: 0;
    }
    .purchase-button-wrap {
        text-align: center;
        min-width: 140px;
    }
    .blog-list .post {
        max-width: 370px;
        margin: 0 auto 30px;
    }
    .sidebar {
        margin-top: 60px;
    }
    .performer-section {
        .col-xs-12 .artist {
            width: 50%;
        }
    }
    .widget .ticket-pricing-item {
        width: calc(50% - 30px);
    }
    .contact-form-wrap .overlay-black {
        padding: 60px;
    }
}

@media (max-width: 639px) {
    .navbar-default {
        .navbar-collapse {
            width: 100%;
            max-width: none;
        }
    }
    .upcoming-album {
        .album-art {
            width: 100%;
            margin-right: 0;
        }
        .album-content {
            width: 100%;
            margin-top: 20px;
        }
    }
    .col-xs-12 {
        .artist {
            width: 100%;
            +.artist {
                margin-top: 30px;
            }
        }
    }
    .artists {
        .col-xs-12+.col-xs-12 {
            margin-top: 30px;
        }
    }
    .show-table {
        tr {
            display: block;
            border: 1px solid #e7e7e7;
            padding: 20px 0;
            +tr {
                margin-top: 10px;
            }
            &:last-child {
                border-bottom: 1px solid #e7e7e7;
            }
            td {
                display: block;
                padding: 0 20px;
                +td {
                    margin-top: 10px;
                }
            }
        }
        .show-ticket {
            width: 100%;
            text-align: left;
        }
    }
    .products {
        .col-xs-4 {
            width: 50%;
        }
    }
    .section-404 .title-404 {
        font-size: 2.4rem;
    }
    .page-title {
        font-size: 3.4rem;
    }
    .music-wrap {
        .col-xs-6 {
            width: 100%;
            .player-main-block {
                max-width: 380px;
                margin: 0 auto 30px;
            }
            .slimScrollDiv {
                max-width: 380px;
                margin: 0 auto;
            }
        }
    }
    .performer-section {
        .col-xs-12 .artist {
            width: 100%;
        }
        .artists {
            .col-xs-12+.col-xs-12 {
                margin-top: 50px;
            }
        }
    }
    .filter-button-group button {
        display: block;
        width: 100%;
        max-width: 320px;
        margin: 0 auto 5px;
        +button {
            margin-left: auto;
        }
    }
}

@media (max-width: 579px) {
    .grid-sizer {
        width: 50%;
    }
    .grid-item {
        width: 50%;
        &.grid-item--width2 {
            width: 50%;
        }
        &.grid-item--width3 {
            width: 50%;
        }
    }
    .band-artists {
        text-align: center;
    }
    .band-artist {
        width: 100%;
        max-width: 234px;
        margin: 0 auto 30px;
        float: none;
    }
    .section-header.left-style .section-title {
        font-size: 2.4rem;
    }
    .author-box .author-thumb+.author-about {
        width: 100%;
        margin-top: 20px;
    }
    .gallery.column-4 {
        .col-xs-6 {
            width: 100%;
        }
    }
}

@media (max-width: 479px) {
    .banner-item {
        height: 460px;
    }
    .upcoming-album {
        .album-title {
            font-size: 2.4rem;
        }
        .release-date {
            font-size: 1.4rem;
        }
    }
    .grid-sizer {
        width: 100%;
    }
    .grid-item {
        width: 100%;
        &.grid-item--width2,
        &.grid-item--width3 {
            width: 100%;
        }
    }
    .products {
        margin-bottom: -30px;
        .col-xs-4 {
            width: 100%;
            .product {
                max-width: 270px;
                margin: 0 auto 30px;
            }
        }
    }
    .blog-grid {
        .col-xs-6 {
            width: 100%;
            .post {
                max-width: 370px;
                float: none;
                margin: 0 auto 30px;
            }
        }
    }
    .recent-albums {
        max-width: 100%;
        .album-thumb {
            width: 100%;
            img {
                max-width: 100%;
            }
        }
    }
    .countdown-stage {
        padding: 10px;
        .section_count {
            .tcount {
                font-size: 2.4rem;
            }
            .text {
                font-size: 1.2rem;
            }
        }
    }
    .subscription-area {
        .subscription {
            [type=email] {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
    .subscription-area .subscription .btn {
        width: 100%;
    }
    .footer-contact p+p {
        margin-left: 0;
        display: block;
    }
    .band-info-items {
        .col-xs-6 {
            width: 100%;
            text-align: center;
        }
    }
    .plyr .plyr__play-large {
        padding: 15px;
    }
    .blog-single .post-metas .post-meta {
        display: block;
        width: 100%;
        +.post-meta {
            margin-left: 0;
        }
    }
    .blog-single .post-date {
        margin-bottom: 0;
    }
    .post-entry {
        margin-top: 15px;
    }
    .commenter-thumb {
        display: none;
        +.comment-body {
            width: 100%;
        }
    }
    .comment-title {
        .comment-date {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
    .comment-content p {
        margin-bottom: 0;
    }
    .widget .ticket-pricing-item {
        width: calc(100% - 30px);
    }
    .contact-form-wrap .overlay-black {
        padding: 60px 15px;
    }
    .fluid-container {
        .fluid-col-1 {
            width: 100%;
        }
    }
    .products {
        margin-bottom: -30px;
        .col-xs-6 {
            width: 100%;
            .product {
                max-width: 370px;
                margin: 0 auto 30px;
            }
        }
    }
}
